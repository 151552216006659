// React & Gatsby

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { uuid } from 'uuidv4';

// Custom

import PillButton from "../PillButton";
import IconsGrid from "../Content/IconsGrid";
import SchemaProduct from "../Schema/SchemaProduct";


const Products = ((props) => {

    let colWidth = props.colWidth ? props.colWidth : 4;

    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            {
                allMarkdownRemark (
                    filter: {
                        fileAbsolutePath: { regex: "\/products\/" }
                    }
                )
                {
                    edges {
                        node {
                            id
                            frontmatter {
                                slug
                                title
                                subtitle
                                alt
                                date
                                image
                                thumbnail
                                price
                                category
                                features
                                link
                                sku
                                text
                                brand
                            }
                            html
                            rawMarkdownBody
                            excerpt
                        }
                    }
                }
            }
        `
    )

    return (
        <Container
            itemScope={''}
            id={'products-grid'}
            className={'product-grid'}
        >
            <Row>
                {allMarkdownRemark.edges.map((data, i) => {
                    const product = data.node;

                    if (props.category !== product.frontmatter.category) return '';

                    let buttonText = 'Download Now';
                    if (parseInt(product.frontmatter.price) !== 0 ) {
                        buttonText = `Buy Now - $${product.frontmatter.price}`;
                    }

                    return (
                        <Col
                            itemScope={''}
                            itemType="http://schema.org/Product"
                            itemProp="productID"
                            content={product.frontmatter.sku}
                            key={product.frontmatter.slug}
                            className={'mb-5'}
                            md={colWidth}
                        >
                            <a href={`/products/${product.frontmatter.slug}`}
                               title={`Permalink to ${product.frontmatter.title}`}
                               rel="bookmark"
                            >
                                <Image
                                    src={product.frontmatter.thumbnail}
                                    alt={`Preview of ${product.frontmatter.title}`}
                                    style={{
                                        width : '100%'
                                    }}
                                />
                            </a>
                            <header itemScope={''}  className={'entry-header'}>
                                <h3 className={'entry-title'}>
                                    <a itemProp={'url'}
                                       href={`/products/${product.frontmatter.slug}/`}
                                       rel={'bookmark'}
                                    >
                                        <span itemProp={'name'}>{product.frontmatter.title}</span>
                                    </a>
                                </h3>
                                <div itemProp="description" className={'entry-excerpt'}>
                                    <p>{product.frontmatter.text || product.excerpt}</p>
                                </div>
                            </header>
                            <div className="buttons">
                                <PillButton
                                    link={product.frontmatter.link}
                                    text={buttonText}
                                    variant={'primary'}
                                    onClick={ () => { window.open(product.frontmatter.link) } }
                                    style={{
                                        width : '100%'
                                    }}
                                />
                            </div>
                            <SchemaProduct
                                name={product.frontmatter.title}
                                image={`https://vectoricons.net${product.frontmatter.image}`}
                                description={product.frontmatter.text || product.excerpt}
                                brand={'Atomic Lotus, LLC'}
                                url={`https://vectoricons.net/products/${product.frontmatter.slug}/`}
                                price={product.frontmatter.price}
                                sku={product.frontmatter.sku}
                            />
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
})

export default Products;
